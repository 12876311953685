/**
 * Type definition for City response from api
 */
export type CityResponseData = {
  label: string
  slug: string
}

export type PaginatedCityResponseData = {
  data: CityResponseData[]
}

/**
 * Type definition for City used inside application
 */
export type City = {
  label: string
  value: string
}

export type PaginatedCities = {
  data: City[]
}

/** TYPES & CLASSES */

export class CityError extends Error {}
