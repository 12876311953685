import { ZuulHttpClient } from "@brokers-utils/http/ZuulHttpClient"
import { CityError, PaginatedCities, PaginatedCityResponseData } from "types/city.data"

import { CityClient } from "../CityClient"
import { mapCityResponse } from "../mapping/city.mapping"

export class CityHttpClient implements CityClient {
  public constructor(private zuulClient: ZuulHttpClient = new ZuulHttpClient()) {}

  async getCities(): Promise<PaginatedCities> {
    try {
      const response = await this.zuulClient.get<PaginatedCityResponseData>("/cities")
      if (response.data === null) throw new CityError()

      const { data } = response.data
      return {
        data: data.map(mapCityResponse),
      }
    } catch (_) {
      throw new CityError()
    }
  }
}
