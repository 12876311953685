import { Body } from "@casavo/habitat"
import { Dispatch, ReactElement, SetStateAction, Suspense, lazy, useEffect, useState } from "react"
import { useRecoilState } from "recoil"

import { cityClient } from "api/cities/factory/CityClientFactory"
import { Layout } from "shared/components/Layout"
import { Loader } from "shared/components/Loader"
import { citiesAtom } from "state/cities"
import { Transaction } from "types/transaction.data"

import { TransactionDocumentWithParentId, TransactionFolder } from "./components/FileExplorer/types"
import { loadingContainer, loadingMessage } from "./styles.css"

export type StepProps = {
  store?: StoreType
  updateStep: Dispatch<SetStateAction<{ step: DashboardStep; store: StoreType | undefined }>>
}

const ChooseAgency = lazy(() => import("./views/ChooseAgency/ChooseAgency"))
const ShareComplete = lazy(() => import("./views/ShareComplete/ShareComplete"))
const ShareDocs = lazy(() => import("./views/ShareDocs/ShareDocs"))
const TransactionList = lazy(() => import("./views/TransactionList/TransactionList"))

export type StoreType = {
  notes?: string
  transaction?: Transaction
  transactionFolder?: TransactionFolder<TransactionFolder<TransactionDocumentWithParentId>>[]
}

type DashboardStep = "list" | "choose-agency" | "share-docs" | "share-complete" | "edit"

const LoadingMessage = (
  <div className={loadingContainer}>
    <div className={loadingMessage}>
      <Body noMargin size="l">
        Getting your data...
      </Body>
      <Loader color="blue" size="big" />
    </div>
  </div>
)

export function Dashboard(): ReactElement {
  const [currentStepWithStore, setCurrentStepWithStore] = useState<{
    step: DashboardStep
    store: StoreType | undefined
  }>({ step: "list", store: undefined })

  const [, setCitiesAtom] = useRecoilState(citiesAtom)

  useEffect(() => {
    cityClient
      .getCities()
      .then((cities) => {
        setCitiesAtom(cities.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [setCitiesAtom])

  return (
    <Layout>
      {currentStepWithStore.step === "list" && (
        <Suspense fallback={LoadingMessage}>
          <TransactionList updateStep={setCurrentStepWithStore} store={currentStepWithStore.store} />
        </Suspense>
      )}
      {currentStepWithStore.step === "choose-agency" && (
        <Suspense fallback={LoadingMessage}>
          <ChooseAgency updateStep={setCurrentStepWithStore} store={currentStepWithStore.store} />
        </Suspense>
      )}
      {currentStepWithStore.step === "share-docs" && (
        <Suspense fallback={LoadingMessage}>
          <ShareDocs updateStep={setCurrentStepWithStore} store={currentStepWithStore.store} />
        </Suspense>
      )}
      {currentStepWithStore.step === "share-complete" && (
        <Suspense fallback={LoadingMessage}>
          <ShareComplete updateStep={setCurrentStepWithStore} store={currentStepWithStore.store} />
        </Suspense>
      )}
    </Layout>
  )
}
