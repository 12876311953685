import { Body } from "@casavo/habitat"
import { ReactElement } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"

import { userAtom } from "state/user"

import { get } from "../../api/zuul"
import InfoImg from "../static/icon/info.svg"
import UserLogo from "../static/icon/shape.svg"
import { handleClickEvent } from "../traking"

import { DropdownMenu } from "./dropDownMenu"
import { DropdownItem } from "./dropDownMenu/DropDownItem"
import { avatar, flex, howItWorks, logo, navBar, rightBarContent, userInfo } from "./Header.css"

const faqUrl =
  "https://casavo.notion.site/casavo/Sales-Management-Platform-SMP-0e7477ba06f048078798c7954da3dc5f"

type DeviceLogo = "mobile" | "desktop"

export const Header = (): ReactElement => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [{ email }] = useRecoilState(userAtom)

  const device: DeviceLogo = window.screen.width <= 480 ? "mobile" : "desktop"

  const CasavoLogo = `${process.env.REACT_APP_PUBLIC_BASE_UI_URL}/logo/agents-wordmark-black-${device}.svg`

  const doLogOut = () => {
    get("logout", {}).finally(() => {
      navigate("/login")
    })
  }
  return (
    <header className={navBar}>
      <Link to="/">
        <img src={CasavoLogo} alt="casavo logo" />
      </Link>
      {email && pathname !== "/login" ? (
        <aside className={rightBarContent}>
          <div className={howItWorks}>
            <InfoImg />
            <a
              href={faqUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                handleClickEvent("HowItWorks")
              }}
            >
              <Body noMargin>How does it work?</Body>
            </a>
          </div>
          <DropdownMenu dropdownAlign="end" trigger={UserBadge}>
            <DropdownItem onClick={doLogOut}>Logout</DropdownItem>
          </DropdownMenu>
        </aside>
      ) : null}
    </header>
  )
}

const UserBadge = () => {
  const [{ first_name, last_name, picture_url }] = useRecoilState(userAtom)
  return (
    <section className={userInfo}>
      <figure className={avatar}>
        {picture_url ? (
          <img src={picture_url} alt="google account" />
        ) : (
          <img className={logo} src={UserLogo} alt="logo account" />
        )}
      </figure>
      <div className={flex}>
        <Body noMargin strong={true}>
          {first_name} {last_name}
        </Body>
        <Body noMargin size="s">
          Agency Specialist
        </Body>
      </div>
    </section>
  )
}
