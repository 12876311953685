/**
 * Map function to convert object from internal application use  to api object
 * @param {CityResponseData} CityResponseData data object from api
 * @return {City} City object to use inside application
 */

import { City, CityResponseData } from "types/city.data"

export const mapCityResponse = (cityResponse: CityResponseData): City => {
  return {
    label: cityResponse.label,
    value: cityResponse.slug,
  }
}
