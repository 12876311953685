import { Navigate } from "react-router-dom"

import TopLoader from "../TopLoader"

import { useLogin } from "./hooks/useLogin"

interface Props {
  component: React.FC
  path?: string
}

export const LoginRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const [userInfo, loading] = useLogin()
  if (loading) {
    return <TopLoader />
  }
  if (userInfo) {
    return <Navigate to="/" />
  }

  return <RouteComponent />
}
