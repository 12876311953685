import { atom } from "recoil"

export type GoogleUser = {
  email: string
  enabled: boolean
  first_name: string
  last_name: string
  picture_url?: string
}

const initialState: GoogleUser = {
  email: "",
  enabled: true,
  first_name: "",
  last_name: "",
  picture_url: "",
}

export const userAtom = atom<GoogleUser>({
  default: initialState,
  key: "userAtom",
})
