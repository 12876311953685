import { useEffect, useState } from "react"

import { GoogleUser } from "state/user"

import { get } from "../../../../api/zuul"

export const useLogin: () => [GoogleUser | null, boolean] = () => {
  const [data, setData] = useState<GoogleUser | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    get<GoogleUser>("admin/me", {})
      .then((response) => {
        setData(response)
      })
      .catch((error) => {
        setData(null)
      })
      .finally(() => setLoading(false))
  }, [])
  return [data, loading]
}
