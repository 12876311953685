import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios"

// const axios = require("axios").default
/**
 * This is the description of the interface
 *
 * @interface HttpClientResponse
 * @field {R} data is out response from api
 * @field {number} status is http status code
 */
type HttpClientResponse<R> = HttpClientEmptyResponse | HttpClientStandardResponse<R>

type HttpClientStandardResponse<R> = {
  data: R
  status: number
}
type HttpClientEmptyResponse = {
  data: null
  status: 204
}

type UploadProgress = (progress: number) => void

type HttpClientErrorProps<R> = {
  data: R
  httpCode?: number
}
export class HttpClientError<R = unknown> extends Error {
  readonly data: R
  readonly httpCode?: number

  public constructor(props: HttpClientErrorProps<R>) {
    super()
    const { data, httpCode } = props
    this.data = data
    this.httpCode = httpCode
  }
}

/**
 * This is the description of the interface
 *
 * @type HttpClientResponse
 * @field {string} baseURL is used as base URL path for api calls
 * @field {Record} headers is used as request header in each api calls
 * @field {boolean} withCredentials is used to tell axios to use session credential
 */
type Props = {
  baseURL: string
  headers: Record<string, string | number | boolean>
  withCredentials?: boolean
}

const mapResponse = <R>(axiosResponse: AxiosResponse<R>): HttpClientResponse<R> => {
  const status = axiosResponse.status
  if (status === 204) return { data: null, status: 204 }
  return { data: axiosResponse.data, status: status }
}

const mapUploadProgress = (progressEvent: any): number => {
  return Math.round((progressEvent.loaded * 100) / progressEvent.total)
}

export class HttpClient {
  private readonly instance: AxiosInstance

  public constructor(props: Props) {
    const { baseURL, headers, withCredentials } = props
    this.instance = axios.create({
      baseURL: baseURL,
      headers: headers,
      withCredentials: withCredentials,
    })
    this.configureResponseInterceptors()
  }

  private configureResponseInterceptors() {
    this.instance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) HttpClient.onAuthError()
        return Promise.reject(error)
      }
    )
  }

  protected getAxiosInstance(): AxiosInstance {
    return this.instance
  }

  private static onAuthError: () => void
  public static setOnAuthError(_cb: () => void) {
    HttpClient.onAuthError = _cb
  }
  public async get<R>(path: string): Promise<HttpClientResponse<R>> {
    return this.instance.get<R>(path).then((res) => mapResponse<R>(res))
  }

  public async post<D, R>(path: string, postData: D): Promise<HttpClientResponse<R>> {
    return this.instance.post<R>(path, postData).then((res) => mapResponse<R>(res))
  }

  public async put<D, R>(
    path: string,
    putData: D,
    headers?: Record<string, string>,
    uploadProgress?: UploadProgress
  ): Promise<HttpClientResponse<R>> {
    const onUploadProgress = uploadProgress
      ? (progressEvent: any) => uploadProgress(mapUploadProgress(progressEvent))
      : undefined

    const config = {
      headers,
      onUploadProgress,
    }

    return this.instance.put<R>(path, putData, config).then((res) => mapResponse<R>(res))
  }

  public async patch<D, R>(path: string, patchData: D): Promise<HttpClientResponse<R>> {
    return this.instance.patch<R>(path, patchData).then((res) => mapResponse<R>(res))
  }
}
