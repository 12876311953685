import { AxiosError } from "axios"

import { HttpClient, HttpClientError } from "./HttpClient"

//only to enum possible values but accept all non matched strings
type ZuulErrorCode = "GenericError" | "SalesCollaborationNotFound" | "AgentNotFound" | string

const ZUUL_BASE_URL = process.env.REACT_APP_ENDPOINT_ZUUL!
const ZUUL_BASE_HEADERS = { Accept: "application/json", "Content-Type": "application/json", mode: "cors" }
const ZUUL_WITH_CREDENTIAL = true

export type ZuulErrorBody = {
  code: ZuulErrorCode
  detail: string
}

type Props = {
  baseURL: string
  headers: Record<string, string | number | boolean>
  withCredentials?: boolean
}

export class ZuulHttpClient extends HttpClient {
  public constructor(
    props: Props = {
      baseURL: ZUUL_BASE_URL,
      headers: ZUUL_BASE_HEADERS,
      withCredentials: ZUUL_WITH_CREDENTIAL,
    }
  ) {
    super({
      baseURL: props.baseURL,
      headers: props.headers,
      withCredentials: props.withCredentials,
    })
    this.configureZuulResponseInterceptors()
  }

  private configureZuulResponseInterceptors() {
    const axiosInstance = this.getAxiosInstance()
    axiosInstance.interceptors.response.use(
      (response) => response,
      function (error: AxiosError<ZuulErrorBody>) {
        const zuulErrorResponse = error.response
        if (zuulErrorResponse) {
          const zuulApiError = new HttpClientError<ZuulErrorBody>({
            data: {
              code: zuulErrorResponse.data.code,
              detail: zuulErrorResponse.data.detail,
            },
            httpCode: zuulErrorResponse.status,
          })
          return Promise.reject(zuulApiError)
        }
        return Promise.reject(error)
      }
    )
  }
}
