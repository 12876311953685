import { Link } from "react-router-dom"

import { notFoundBody } from "pages/styles.css"
import { Layout } from "shared/components/Layout"

export interface INotFoundProps {}

export function NotFound(props: INotFoundProps) {
  return (
    <Layout>
      <div className={notFoundBody}>
        404 - Not found
        <Link to="/">Return to home</Link>
      </div>
    </Layout>
  )
}
