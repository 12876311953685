import { HabitatTheme } from "@casavo/habitat"
import { BrowserTracing } from "@sentry/browser"
import * as Sentry from "@sentry/react"
import { createRoot } from "react-dom/client"
import TagManager from "react-gtm-module"
import { BrowserRouter } from "react-router-dom"
import { RecoilRoot } from "recoil"

// eslint-disable-next-line import/no-unresolved
import "@casavo/habitat/style.css"
import { SMPTheme } from "theme.css"
import "./global.css"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Header } from "./shared/components/Header"

const tagManagerArgs = {
  auth: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH}`,
  gtmId: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`,
  preview: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW}`,
}

TagManager.initialize(tagManagerArgs)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      blockAllMedia: true,
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
    }),
  ],
  release: process.env.REACT_APP_SENTRY_RELEASE,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_ENV === "staging" ? 1.0 : 0.1,

  tracesSampleRate: 1.0,
})

window.addEventListener("error", (error) => {
  console.log(error)
})

// the VE theme needs to be applied on the body tag since some elements are created
// outside the React root element
document.body.classList.add(SMPTheme)
document.body.classList.add(HabitatTheme)

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <Header />
      <App />
    </BrowserRouter>
  </RecoilRoot>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
