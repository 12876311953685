import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useRecoilState } from "recoil"

import { userAtom } from "state/user"

import TopLoader from "../TopLoader"

import { useLogin } from "./hooks/useLogin"

interface Props {
  component: React.FC
  path?: string
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const [userInfo, loading] = useLogin()
  const [, setUserAtom] = useRecoilState(userAtom)

  useEffect(() => {
    userInfo && setUserAtom(userInfo)
  }, [userInfo, setUserAtom])
  if (loading) {
    return <TopLoader />
  }
  if (userInfo) {
    return <RouteComponent />
  }

  return <Navigate to="/login" />
}
