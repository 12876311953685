export const getPage = (): string => document.location.pathname + document.location.search

export const getLocation = (): string =>
  document.location.protocol +
  "//" +
  document.location.hostname +
  document.location.pathname +
  document.location.search

export const dataLayerPush = (params: Record<string, unknown>): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      location: getLocation(),
      page: getPage(),
      ...params,
    })
  }
}

type PropsEventInteraction = {
  eventAction: string
  eventCategory: string
  eventLabel: string
  eventName: string
  eventProperties: object | undefined
  eventType?: string
  eventValue?: string
}

type Props = PropsEventInteraction & {
  user?: {
    email?: string
  }
}

export const sendEvent = ({ eventName, ...props }: Props): void => {
  if (typeof window.dataLayer !== "undefined") {
    dataLayerPush({ event: eventName, ...props })
  }
}

export const sendEventInteraction = ({
  eventAction,
  eventCategory,
  eventLabel,
  eventName,
  eventProperties,
  eventType = "generic",
  eventValue,
}: PropsEventInteraction): void =>
  sendEvent({ eventAction, eventCategory, eventLabel, eventName, eventProperties, eventType, eventValue })

export const handleClickEvent = (eventLabel: string, eventProperties: object | undefined = undefined) => {
  sendEventInteraction({
    eventAction: "Click",
    eventCategory: "AgencyDashboard",
    eventLabel,
    eventName: "casavo.user-interaction",
    eventProperties,
  })
}

export const handleFieldUpdateEvent = (eventLabel: string) => {
  sendEventInteraction({
    eventAction: "FieldUpdate",
    eventCategory: "AgencyDashboard",
    eventLabel,
    eventName: "casavo.user-interaction",
    eventProperties: undefined,
  })
}
