import { PaginatedCities } from "types/city.data"

import { CityClient } from "../CityClient"
import { cityMapped } from "../mock/city.mapped"

type Props = {
  hasToApplyDelay: boolean
}

const defaultProps: Props = { hasToApplyDelay: true }
export class CityMockClient implements CityClient {
  hasToApplyDelay: boolean

  constructor(props: Props = defaultProps) {
    this.hasToApplyDelay = props.hasToApplyDelay
  }
  getCities(): Promise<PaginatedCities> {
    return Promise.resolve({ data: [cityMapped] })
  }
}
