import { H2, Button } from "@casavo/habitat"

import { googleLoginButton, loginBody, loginCTA, loginImg, loginTitle } from "pages/styles.css"
import { Layout } from "shared/components/Layout"
import HandShake from "shared/static/image/handshake.png"

export interface ILoginProps {}

export function Login(props: ILoginProps) {
  return (
    <Layout>
      <div className={loginBody}>
        <hgroup className={loginTitle}>
          <H2>Manage your relationships with real estate agencies</H2>
        </hgroup>
        <img className={loginImg} src={HandShake} alt="handshake" />
        <div className={loginCTA}>
          <Button theme="light">
            <a
              className={googleLoginButton}
              data-testid="google-login-button"
              href={`${process.env.REACT_APP_ENDPOINT_ZUUL}login/google?redirect=${window.location}`}
            >
              Sign in with Google
            </a>
          </Button>
        </div>
      </div>
    </Layout>
  )
}
