/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { dropdownItemContainer } from "./styles.css"

type DropdownProps = {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => void
}

export const DropdownItem: React.FC<DropdownProps> = ({ children, disabled = false, onClick }) => {
  return (
    <div
      className={dropdownItemContainer({ disabled })}
      onClick={(e) => {
        disabled && e.stopPropagation()
        !disabled && onClick()
      }}
    >
      {children}
    </div>
  )
}
