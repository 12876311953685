import { useEffect } from "react"
import { Toaster } from "react-hot-toast"
import { Route, Routes, useNavigate } from "react-router-dom"

import { ZuulHttpClient } from "@brokers-utils/http/ZuulHttpClient"
import { Dashboard } from "pages/Dashboard"
import { Login } from "pages/Login/Login"
import { NotFound } from "pages/NotFound"

import { apiClient } from "./api/zuul"
import { LoginRoute } from "./shared/components/routing/LoginRoute"
import { PrivateRoute } from "./shared/components/routing/PrivateRoute"

function App() {
  const navigate = useNavigate()

  useEffect(() => {
    const redirect = () => navigate("/login")
    apiClient.setOnAuthError(redirect)
    ZuulHttpClient.setOnAuthError(redirect)
  }, [navigate])

  useEffect(() => {
    if (window && window.dataLayer) window.dataLayer.push({ event: "casavo.pageview" })
  }, [])

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginRoute component={Login} />} />
        <Route path="/" element={<PrivateRoute component={Dashboard} />} />
        <Route path="/Dashboard" element={<PrivateRoute component={Dashboard} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster containerStyle={{ marginTop: 140 }} />
    </>
  )
}

export default App
