import { CityClient } from "../CityClient"
import { CityHttpClient } from "../clients/CityHttpClient"
import { CityMockClient } from "../clients/CityMockClient"

export const cityFactoryClient = (
  hasToUseMock: boolean = process.env.REACT_APP_TRANSACTION_CLIENT_MOCKED === "true"
): CityClient => {
  return hasToUseMock ? new CityMockClient() : new CityHttpClient()
}
export const cityClient = cityFactoryClient()
