export class ApiClient {
  onAuthError = () => {}

  setOnAuthError(callBack: () => void) {
    this.onAuthError = callBack
  }

  async fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    let response = await fetch(input, init)
    if (!response.ok && response.status === 401) {
      this.onAuthError()
    }
    return response
  }
}

export const apiClient = new ApiClient()

type GetOptions = {
  headers?: HeadersInit
  mode?: RequestMode
  signal?: AbortSignal
}

export const get = async <T>(endpoint: string, { headers, mode, signal }: GetOptions) => {
  try {
    const response = await apiClient.fetch(`${process.env.REACT_APP_ENDPOINT_ZUUL}${endpoint}`, {
      credentials: "include",
      headers: { Accept: "application/json", "Content-Type": "application/json", ...headers },
      method: "GET",
      mode,
      signal,
    })
    if (!response.ok) {
      throw new Error()
    }
    return (await response.json()) as T
  } catch (e: any) {
    throw new Error(e)
  }
}

type PostOptions = {
  body?: BodyInit
  headers?: HeadersInit
  mode?: RequestMode
  signal?: AbortSignal
}

export const post = async <T>(endpoint: string, { body, headers, mode, signal }: PostOptions) => {
  try {
    const response = await apiClient.fetch(`${process.env.REACT_APP_ENDPOINT_ZUUL}${endpoint}`, {
      body,
      credentials: "include",
      headers: { Accept: "application/json", "Content-Type": "application/json", ...headers },
      method: "POST",
      mode,
      signal,
    })

    if (!response.ok) {
      throw new Error(await response.json())
    }

    return (await response.json()) as T
  } catch (e: any) {
    throw new Error(e)
  }
}

export const postEmptyResponse = async (endpoint: string, { body, headers, mode, signal }: PostOptions) => {
  try {
    const response = await apiClient.fetch(`${process.env.REACT_APP_ENDPOINT_ZUUL}${endpoint}`, {
      body,
      credentials: "include",
      headers: { Accept: "application/json", "Content-Type": "application/json", ...headers },
      method: "POST",
      mode,
      signal,
    })

    if (!response.ok) {
      throw new Error(await response.json())
    }

    return response
  } catch (e: any) {
    throw new Error(e)
  }
}
