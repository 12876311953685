import { loaderContainer, loaderInner } from "./Loader.css"

type LoaderProps = {
  color: "white" | "blue"
  size: "little" | "big"
}

export const Loader: React.FC<LoaderProps> = ({ color, size }) => {
  return (
    <div className={loaderContainer({ color, size })} data-testid="loader">
      <div className={loaderInner({ color, size })}></div>
    </div>
  )
}
