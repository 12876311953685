import { Body } from "@casavo/habitat"
import * as Dropdown from "@radix-ui/react-dropdown-menu"
import { useState } from "react"

import { dropdownMenuContent, dropdownMenuTrigger } from "./styles.css"

type Props = {
  children: React.ReactNode
  "data-testid"?: string
  dropdownAlign?: "center" | "end" | "start"
  hidden?: boolean
  trigger: React.ReactNode | ((o: { open: boolean }) => JSX.Element)
}

export const DropdownMenu: React.FC<Props> = ({
  children,
  "data-testid": dataTestId,
  dropdownAlign,
  hidden,
  trigger: Trigger,
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger className={dropdownMenuTrigger({ hidden })} data-testid={dataTestId}>
        {typeof Trigger === "function" ? <Trigger open={open} /> : Trigger}
      </Dropdown.Trigger>
      <Dropdown.Content
        align={dropdownAlign}
        className={dropdownMenuContent}
        data-testid="opened-menu"
        sideOffset={8}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(false)
        }}
      >
        <Body noMargin>{children}</Body>
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
